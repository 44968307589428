@import '../../node_modules/bootstrap-4-grid/css/grid.min.css';

@import '../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';

@import '../../node_modules/swiper/swiper-bundle.min.css';

@font-face {
  font-family: "Lobster";
  src: url("../fonts/Lobster-Regular.woff") format("woff");
  src: url("../fonts/Lobster-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url("../fonts/TitilliumWeb-Bold.woff") format("woff");
  src: url("../fonts/TitilliumWeb-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url("../fonts/TitilliumWeb-Regular.woff") format("woff");
  src: url("../fonts/TitilliumWeb-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
  max-height: 9001em;
  line-height: 1.2;
  -webkit-text-size-adjust: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  width: 100%;
  min-width: 320px;
  margin: 0;
  font-size: 22px;
  font-family: "TitilliumWeb", sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  backface-visibility: hidden;
  text-rendering: geometricPrecision;
}

body.black-hero-text .hero {
  color: #000;
}

body.white-hero-text .hero {
  color: #ffffff;
}

h1,
.title-2,
.title-1,
h2,
h3,
.title-3,
h4,
.title-4,
h5,
.title-5,
h6,
.title-6 {
  margin: 0;
  font-weight: 800;
  font-family: "TitilliumWeb", sans-serif;
  line-height: 1.2em;
}

h1,
.title-2,
.title-1 {
  font-size: 50px;
  line-height: 1.08em;
}

h2 {
  font-size: 64px;
}

h3,
.title-3 {
  font-size: 36px;
  margin-bottom: 23px;
}

h4,
.title-4 {
  font-size: 28px;
  margin-bottom: 8px;
}

h5,
.title-5 {
  font-size: 22px;
  line-height: 1.2em;
}

h6,
.title-6 {
  font-size: 18px;
  line-height: 1.2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: 1.2em;
  margin: 0;
  background-color: transparent;
}

a:visited {
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bold;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img,
picture,
source {
  max-width: 100%;
  width: 100%;
  display: block;
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

input {
  line-height: 1em;
}

button,
input {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: hidden;
  resize: none;
}

textarea:focus {
  outline: none;
}

label {
  cursor: pointer;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

p,
li {
  margin: 0;
  color: inherit;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

section.section {
  position: relative;
  overflow: hidden;
}

tr {
  line-height: 1.5em;
}

figure {
  margin: 0;
}

blockquote {
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}

input[type=date] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

input[type=date]::-webkit-inner-spin-button {
  display: none;
}

input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: relative;
  right: -10px;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: 16px;
  color: #000;
}

textarea::-moz-placeholder,
input::-moz-placeholder {
  font-size: 16px;
  color: #000;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  font-size: 16px;
  color: #000;
}

textarea::placeholder,
input::placeholder {
  font-size: 16px;
  color: #000;
}

input {
  outline: none;
}

.main-wrapper {
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

.main,
main {
  display: block;
  position: relative;
}

.locked {
  overflow: hidden;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.global-wrapper {
  height: 100%;
}

.btn {
  background-color: #00d100;
  display: inline-block;
  border-radius: 40px;
  color: #ffffff;
  font-size: 22px;
  font-weight: 800;
  padding: 10px 60px;
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.btn:hover {
  background-color: #009b00;
}

.btn_border {
  border: 1px solid #00d100;
  background-color: #ffffff;
  color: #00d100;
}

.btn_border:hover {
  color: #009b00;
  border-color: #009b00;
  background-color: #ffffff;
}

.header {
  padding: 60px 0 50px 0;
  width: 100%;
  z-index: 100;
  position: relative;
}

.header::before {
  content: "";
  width: 100%;
  height: 23px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  background-color: #00d100;
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: inline-block;
  font-weight: 800;
  z-index: 100;
}

.logo p {
  font-size: 20px;
  margin-bottom: 6px;
}

.logo h1,
.logo .title-1,
.logo .title-2 {
  font-family: "Lobster";
}

.nav {
  font-weight: 800;
  font-size: 20px;
  margin-top: 20px;
}

.nav-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.nav-list > li {
  position: relative;
}

.nav-list > li:not(:last-child) {
  margin-right: 50px;
}

.nav-list > li.selected {
  color: #00d100;
}

.nav-list > li.has-children {
  position: relative;
  padding-right: 10px;
}

.nav-list > li.has-children::before {
  content: "";
  background-image: url(../images/down.svg);
  position: absolute;
  top: 18px;
  right: 7px;
  width: 20px;
  height: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.nav-list > li > a {
  transition: color 0.3s ease;
  padding: 10px 15px;
  display: block;
}

.subnav {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  font-size: 16px;
  transition: opacity 0.3s ease;
  background-color: #ffffff;
  z-index: 1;
  pointer-events: none;
  padding: 0 0 15px 0;
}

.subnav > li {
  color: #000;
  margin-bottom: 12px;
}

.subnav > li > a {
  padding: 2px 15px;
  position: relative;
}

.subnav > li > a::before {
  content: "";
  height: 2px;
  background-color: #000;
  position: absolute;
  bottom: 2px;
  left: 15px;
  width: 0;
  transition: width 0.3s ease;
}

.subnav > li > a:hover::before {
  width: 70%;
}

.burger {
  position: relative;
  width: 22px;
  height: 16px;
  z-index: 101;
  display: none;
}

.burger_item {
  pointer-events: none;
  position: absolute;
  width: 26px;
  height: 4px;
  border-radius: 4px;
  background-color: #000;
  left: 0;
  transition: 0.5s ease-out;
}

.burger_item:first-child {
  top: 0;
}

.burger_item:nth-child(2) {
  top: 50%;
  margin-top: -2px;
}

.burger_item:last-child {
  bottom: 0;
}

.burger.active .burger_item:first-child {
  transform: rotate(45deg);
  top: 6px;
}

.burger.active .burger_item:nth-child(2) {
  width: 0;
}

.burger.active .burger_item:last-child {
  transform: rotate(-45deg);
  bottom: 6px;
}

.footer {
  color: #ffffff;
  background-color: #202635;
  padding-top: 65px;
}

.footer__title {
  color: #00d100;
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 20px;
}

.footer ul {
  font-size: 20px;
  margin-bottom: 27px;
}

.footer__social {
  display: flex;
  gap: 40px;
}

.footer__social li {
  width: 40px;
}

.footer__box_logo {
  margin-bottom: 50px;
}

.footer__links {
  padding: 50px 0 0 150px;
  font-weight: 800;
  margin-bottom: 0;
}

.footer__links li {
  margin-bottom: 10px;
}

.footer__links li a {
  line-height: 1em;
}

.footer__logo {
  width: 200px;
  margin-right: 0;
  margin-left: auto;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  max-width: 420px;
  padding: 0 15px;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.popup.active {
  opacity: 1;
  pointer-events: initial;
}

.popup__wrapper {
  position: relative;
  padding: 30px 20px;
  background-color: #ffffff;
  text-align: center;
  box-shadow: -1px 4px 15px 1px rgba(0, 0, 0, 0.2);
}

.popup h5,
.popup .title-5 {
  margin-bottom: 15px;
}

.popup_close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  padding: 0;
  border: none;
  outline: none;
}

.popup_close svg {
  width: 100%;
  height: 100%;
}

.popup__footer {
  margin-top: 30px;
}

.popup__footer .btn {
  width: 100%;
  text-transform: uppercase;
}

.popup__footer .btn:not(:last-child) {
  margin-bottom: 10px;
}

.overlay {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  transition: opacity 0.3s ease;
}

.overlay.active {
  opacity: 1;
  pointer-events: initial;
}

.hero {
  background-color: #fff2f8;
}

.hero__image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  bottom: -100px;
}

.hero__image::before {
  content: "";
  width: 900px;
  height: 900px;
  border-radius: 50%;
  background-color: #00d100;
  opacity: 0.3;
  position: absolute;
  bottom: -320px;
  left: -240px;
  z-index: 0;
}

.hero__image img {
  position: relative;
}

.hero__content {
  text-align: right;
  padding: 153px 60px 120px 0;
  position: relative;
  z-index: 1;
}

.hero__content p {
  font-size: 40px;
  margin-bottom: 5px;
}

.hero__content h2 {
  margin-bottom: 70px;
}

.hero-inner {
  aspect-ratio: 16/7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e7e6e4;
}

.hero-inner::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-team {
  aspect-ratio: 16/7;
}

.hero-team img {
  object-fit: cover;
}

.hero_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero_bg img {
  height: 100%;
  object-fit: cover;
}

.news {
  padding: 120px 0 100px 0;
}

.news-card {
  max-width: 374px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 20px;
  display: block;
}

.news-card__image {
  height: 280px;
  overflow: hidden;
}

.news-card__image:hover img {
  transform: scale(1.05);
}

.news-card__image img {
  position: relative;
  object-fit: cover;
  height: 100%;
  transform: scale(1);
  transition: transform 0.8s ease;
}

.news-card__content {
  padding: 30px 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.news-card__content p {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-card__content h5,
.news-card__content .title-5 {
  margin-bottom: 28px;
}

.about {
  padding: 40px 0 50px 0;
  background-color: #fff2f8;
  margin-bottom: 115px;
}

.about::before {
  content: "";
  width: 31%;
  height: 100%;
  background-color: #202635;
  position: absolute;
  left: 0;
  top: 0;
}

.about__row {
  display: flex;
  margin: 0 -15px;
  justify-content: space-between;
}

.about__image {
  padding: 55px 15px;
  position: relative;
  max-width: 710px;
}

.about__content {
  padding: 0 15px;
  justify-content: space-between;
  width: 45%;
  position: relative;
}

.about__content h3,
.about__content .title-3 {
  font-size: 40px;
  margin-bottom: 5px;
  font-weight: 400;
}

.about__content h2 {
  margin-bottom: 40px;
}

.faq {
  background-color: #fff2f8;
  padding: 30px 0;
  margin-bottom: 115px;
}

.accordion__item:not(:first-child) {
  border-top: 1px solid #000;
}

.accordion__item.active .accordion__panel {
  margin-top: 20px;
}

.accordion__item.active .plus::before {
  background-image: url(../images/close.svg);
}

.accordion__btn {
  color: #000;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  font-size: 40px;
  font-weight: 800;
  outline: none;
  transition: 0.3s;
  padding: 20px 30px 0 60px;
  font-weight: 500;
  position: relative;
  z-index: 1;
}

.accordion__panel {
  padding: 0 0 0 60px;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin: 0 -15px 20px -15px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.accordion__panel p {
  margin-bottom: 16px;
}

.accordion__panel a {
  color: #00d100;
}

.accordion__panel ul {
  margin: 16px 0;
}

.accordion__panel ul li {
  position: relative;
  padding-left: 15px;
}

.accordion__panel ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 12px;
  left: 0;
}

.accordion__panel ol {
  counter-reset: my-awesome-counter;
  margin: 16px 0;
}

.accordion__panel ol li {
  position: relative;
  counter-increment: my-awesome-counter;
}

.accordion__panel ol li::before {
  content: counter(my-awesome-counter) ". ";
  font-size: 18px;
}

.accordion__content {
  padding: 0 15px;
}

.accordion__image {
  padding: 0 15px;
  width: 460px;
  flex: 0 0 460px;
  min-height: 280px;
  margin-bottom: 20px;
}

.accordion__image img {
  height: 100%;
  object-fit: cover;
}

.plus {
  width: 24px;
  height: 24px;
  position: absolute;
  display: inline-block;
  left: 0;
  top: 30px;
}

.plus::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(../images/down.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.section-only-text {
  padding: 80px 0 40px 0;
}

.section-only-text__content h2 {
  margin-bottom: 40px;
}

.section-only-text__content p:not(:last-child) {
  margin-bottom: 16px;
}

.section-team {
  padding: 80px 0 0 0;
}

.section-team__title {
  text-align: center;
  margin-bottom: 60px;
}

.team-card {
  max-width: 220px;
  margin-bottom: 20px;
}

.team-card__content {
  padding: 40px 0;
}

.section-article {
  padding: 80px 0;
}

.section-article__content h1,
.section-article__content .title-1,
.section-article__content .title-2,
.section-article__content h2,
.section-article__content h3,
.section-article__content .title-3,
.section-article__content h4,
.section-article__content .title-4,
.section-article__content h5,
.section-article__content .title-5,
.section-article__content h6,
.section-article__content .title-6 {
  margin-bottom: 20px;
}

.section-article__content p {
  margin-bottom: 16px;
}

.section-article__content ul {
  margin: 16px 0;
}

.section-article__content ul li {
  position: relative;
  padding-left: 15px;
}

.section-article__content ul li:not(:last-child) {
  margin-bottom: 3px;
}

.section-article__content ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 12px;
  left: 0;
}

.section-article__content ol {
  counter-reset: my-awesome-counter;
  margin: 16px 0;
}

.section-article__content ol li {
  position: relative;
  counter-increment: my-awesome-counter;
}

.section-article__content ol li:not(:last-child) {
  margin-bottom: 3px;
}

.section-article__content ol li::before {
  content: counter(my-awesome-counter) ". ";
  font-size: 18px;
}

.section-article__content a {
  color: #00d100;
}

.section-article__content .btn {
  color: #ffffff;
  margin-top: 60px;
}

.section-article__content iframe {
  width: 100%;
  margin: 20px 0;
}

.section-article__image {
  max-width: 460px;
  margin: 0 0 16px auto;
}

.video_link {
  position: relative;
  cursor: pointer;
}

.video_link::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.video_link::before {
  content: "";
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../images/play.svg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

@media only screen and (min-width: 992px) {
  .nav-list > li:hover > a {
    color: #00d100;
  }

  .nav-list > li:hover .subnav {
    opacity: 1;
    pointer-events: initial;
  }

  .nav-list > li:hover .subnav a {
    color: #000;
  }
}

@media only screen and (max-width: 1920px) {
  .hero-inner {
    display: block;
    aspect-ratio: auto;
  }
}

@media only screen and (max-width: 1440px) {
  .hero__image {
    width: 52%;
    bottom: 0;
  }
}

@media only screen and (max-width: 1280px) {
  .header {
    padding: 50px 0 30px 0;
  }

  .nav-list > li:not(:last-child) {
    margin-right: 30px;
  }

  .footer__links {
    padding: 30px 0 0 0;
  }

  .about__content {
    width: 50%;
  }

  .section-only-text {
    padding: 60px 0 40px;
  }
}

@media only screen and (max-width: 1124px) {
  h1,
  .title-2,
  .title-1 {
    font-size: 36px;
  }

  h2 {
    font-size: 50px;
  }

  .btn {
    font-size: 18px;
  }

  .logo p {
    font-size: 18px;
  }

  .hero__image {
    width: 45%;
  }

  .hero__image::before {
    width: 700px;
    height: 700px;
  }

  .hero__content {
    padding: 80px 0px 80px 0;
  }

  .hero__content p {
    font-size: 30px;
  }

  .hero__content h2 {
    margin-bottom: 40px;
  }

  .about__content h3,
  .about__content .title-3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 992px) {
  body {
    font-size: 18px;
  }

  body.white-hero-text .hero {
    color: #000;
  }

  h4,
  .title-4 {
    font-size: 26px;
  }

  .btn {
    padding: 10px 20px;
  }

  .header {
    padding: 40px 0 20px 0;
  }

  .header::before {
    height: 16px;
  }

  .header-row {
    position: relative;
  }

  .header-row::before {
    content: "";
    width: calc(100% + 30px);
    height: calc(100% + 130px);
    position: absolute;
    top: -130px;
    left: -15px;
    background-color: #ffffff;
    z-index: 7;
  }

  .header__nav {
    margin-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-top: 150px;
    background-color: #ffffff;
    pointer-events: none;
    opacity: 0;
    z-index: 5;
    transform: translateX(-20px);
    overflow-x: auto;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }

  .header__nav.active {
    opacity: 1;
    pointer-events: initial;
    transform: translateX(0);
  }

  .header__nav .nav-list {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 18px;
    max-width: 200px;
  }

  .nav-list > li {
    width: 100%;
  }

  .nav-list > li:not(:last-child) {
    margin-right: 0;
  }

  .nav-list > li.has-children::before {
    top: 16px;
  }

  .subnav {
    pointer-events: initial;
    display: none;
    position: relative;
    opacity: 1;
  }

  .subnav > li > a {
    padding: 0 30px;
  }

  .subnav > li > a::before {
    left: 30px;
  }

  .burger {
    display: inline-block;
  }

  .footer__logo {
    margin-right: auto;
    margin-left: 0;
  }

  .hero__image {
    opacity: 0.5;
    left: 0;
    right: 0;
  }

  .hero-inner::after {
    opacity: 1;
  }

  .about {
    margin-bottom: 0;
  }

  .about::before {
    height: 30%;
  }

  .about__row {
    flex-wrap: wrap;
  }

  .about__image {
    width: 100%;
    max-width: 100%;
    padding: 0 0 40px 0;
  }

  .about__content {
    width: 100%;
  }

  .faq {
    padding: 20px 0;
    margin-bottom: 0;
  }

  .accordion__btn {
    font-size: 32px;
    padding-left: 30px;
  }

  .accordion__panel {
    flex-direction: column;
    padding-left: 30px;
  }

  .accordion__panel ul li::before {
    top: 9px;
  }

  .accordion__content {
    max-width: 100%;
  }

  .accordion__image {
    width: 100%;
    flex: 0 0 100%;
    display: none;
  }

  .plus {
    width: 20px;
    height: 20px;
    top: 28px;
  }

  .section-only-text {
    padding: 40px 0;
  }

  .section-only-text__content h2 {
    margin-bottom: 20px;
  }

  .section-team {
    padding: 60px 0 0 0;
  }

  .section-team__title {
    margin-bottom: 40px;
  }

  .team-card__content {
    padding: 20px 0;
    font-size: 18px;
  }

  .section-article {
    padding: 60px 0 0 0;
  }

  .section-article__content {
    margin-bottom: 40px;
  }

  .section-article__content ul li::before {
    top: 9px;
  }

  .section-article__content .btn {
    margin-top: 40px;
  }

  .section-article__image {
    margin: 16px auto 16px auto;
  }
}

@media only screen and (max-width: 992px) and (max-width: 768px) {
  .header-row::before {
    height: calc(100% + 160px);
  }
}

@media only screen and (max-width: 768px) {
  h2 {
    font-size: 40px;
  }

  .logo p {
    font-size: 16px;
    margin-bottom: 0;
  }

  .hero__image {
    left: 50%;
    transform: translate(-50%, 0);
    width: 70%;
    bottom: -10%;
  }

  .hero__content p {
    font-size: 20px;
  }

  .news {
    padding: 80px 0 40px 0;
  }

  .news-card {
    margin: 0 auto 40px auto;
    height: auto;
  }

  .about__content h3,
  .about__content .title-3 {
    font-size: 20px;
  }

  .about__content h2 {
    margin-bottom: 20px;
  }

  .faq {
    padding: 20px 0 0 0;
  }

  .accordion__btn {
    font-size: 24px;
  }

  .plus {
    top: 23px;
  }

  .section-team {
    padding: 20px 0 0 0;
  }

  .section-team__title {
    margin-bottom: 20px;
  }

  .team-card__content {
    font-size: 16px;
  }

  .section-article__image {
    max-width: calc(100% + 30px);
    margin: 0 -15px 0 -15px;
    width: calc(100% + 30px);
  }
}

@media only screen and (max-width: 576px) {
  .hero__image {
    width: 100%;
  }

  .accordion__btn {
    font-size: 20px;
  }

  .plus {
    width: 16px;
    height: 16px;
  }

  .team-card {
    max-width: 100%;
  }
}

@media only screen and (max-width: 340px) {
  h3,
  .title-3 {
    font-size: 28px;
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 992px) {
  .nav-list > li:hover .subnav {
    position: relative;
  }
}